nowuiDashboard = {
  misc:{
      navbar_menu_visible: 0
  },

  initMinimizeSidebar:function(){
      if($('.sidebar-mini').length != 0){
        sidebar_mini_active = true;
      }

      $('#minimizeSidebar').click(function(){
          var $btn = $(this);

          if(sidebar_mini_active == true){
              $('body').removeClass('sidebar-mini');
              sidebar_mini_active = false;
              nowuiDashboard.showSidebarMessage('Sidebar mini deactivated...');
          }else{
              $('body').addClass('sidebar-mini');
              sidebar_mini_active = true;
              nowuiDashboard.showSidebarMessage('Sidebar mini activated...');
          }

          // we simulate the window Resize so the charts will get updated in realtime.
          var simulateWindowResize = setInterval(function(){
              window.dispatchEvent(new Event('resize'));
          },180);

          // we stop the simulation of Window Resize after the animations are completed
          setTimeout(function(){
              clearInterval(simulateWindowResize);
          },1000);
      });
  },

  showSidebarMessage: function(message){
    try {
      $.notify({
          icon: "now-ui-icons ui-1_bell-53",
          message: message
        },{
            type: 'info',
            timer: 4000,
            placement: {
                from: 'top',
                align: 'right'
            }
        });
    } catch (e) {
      console.log('Notify library is missing, please make sure you have the notifications library added.');
    }

  }

};
