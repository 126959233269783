$(document).ready(function(){

  if( $('.full-screen-map').length == 0 && $('.bd-docs').length == 0 ){
    // On click navbar-collapse the menu will be white not transparent
    $('.collapse').on('show.bs.collapse', function () {
        $(this).closest('.navbar').removeClass('navbar-transparent').addClass('bg-white');
    }).on('hide.bs.collapse', function () {
        $(this).closest('.navbar').addClass('navbar-transparent').removeClass('bg-white');
    });
  }

  nowuiDashboard.initMinimizeSidebar();

  $navbar = $('.navbar[color-on-scroll]');
  scroll_distance = $navbar.attr('color-on-scroll') || 500;

  // Check if we have the class "navbar-color-on-scroll" then add the function to remove the class "navbar-transparent" so it will transform to a plain color.
  if($('.navbar[color-on-scroll]').length != 0){
      nowuiDashboard.checkScrollForTransparentNavbar();
      $(window).on('scroll', nowuiDashboard.checkScrollForTransparentNavbar)
  }

  $('.form-control').on("focus", function(){
      $(this).parent('.input-group').addClass("input-group-focus");
  }).on("blur", function(){
      $(this).parent(".input-group").removeClass("input-group-focus");
  });

  // Activate bootstrapSwitch
  $('.bootstrap-switch').each(function(){
      $this = $(this);
      data_on_label = $this.data('on-label') || '';
      data_off_label = $this.data('off-label') || '';

      $this.bootstrapSwitch({
          onText: data_on_label,
          offText: data_off_label
      });
  });
});
